import {Delete, get, post, put} from "@/api/axios";

export const list = params => get('/web/house/new/list', params);

export const updateIsSell = params => put('/web/house/new/sell/update', params);

// /web/house/new/recommend/update PUT
export const updateRecommend = params => put('/web/house/new/recommend/update', params);

// /web/house/new/state/update PUT
export const updateState = params => put('/web/house/new/state/update', params);

// /web/house/new/top/update PUT
export const updateTop = params => put('/web/house/new/top/update', params);

// /web/house/new/add POST
export const add = params => post('/web/house/new/add', params);

// /web/house/new/view/{id} get
export const view = params => get('/web/house/new/view/' + params.id, '')

// /web/house/new/update PUT
export const update = params => put('/web/house/new/update', params);

// /web/house/new/delete/{id} DELETE
export const deleteById = params => Delete('/web/house/new/delete/' + params.id, '')

// /web/house/new/vr/update PUT
export const updateVr = params => put('/web/house/new/vr/update', params);

// /web/house/new/agent/update PUT
export const updateAgent = params => put('/web/house/new/agent/update', params);

// /web/house/new/label/list get
export const listLabel = params => get('/web/house/new/label/list', params);
