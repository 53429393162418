<template>
  <el-dialog
      :title="createType === 'create' ? '新增' : '编辑'"
      :visible.sync="show"
      width="50%"
      :before-close="handleClose">

    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption"
               @submit="handleSubmit"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore">
      <!-- <template slot="bedroomOfficeToilet">
        <div class="flex align-items">
          <div class="width_30 flex">
            <el-select v-model="form.bedroom" clearable @change="changeBedroom">
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
            <el-button type="text" style="color: #909399;padding: 0 10px">室</el-button>
          </div>
          <div class="width_30 flex">
            <el-select v-model="form.office" clearable @change="changeOffice">
              <el-option :value="0" label="0"></el-option>
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
            <el-button type="text" style="color: #909399;padding: 0 10px">厅</el-button>
          </div>
          <div class="width_30 flex">
            <el-select v-model="form.toilet" clearable @change="changeToilet">
              <el-option :value="0" label="0"></el-option>
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
            <el-button type="text" style="color: #909399;padding: 0 10px">卫</el-button>
          </div>
        </div>
      </template> -->

      <template slot="floorage">
          <el-input v-model="form.floorage"
                    placeholder="请输入 建筑面积"
                    clearable
                    @blur="changeBlur($event,'floorage',999999,'decimal')"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">平米</template>
          </el-input>
      </template>

      <template slot="sellPrice">
        <el-input v-model="form.sellPrice"
                  placeholder="请输入 期望售价"
                  clearable
                  @blur="changeBlur($event,'sellPrice',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">万元</template>
        </el-input>
      </template>
      <template slot="capacityNum">
        <el-input v-model="form.capacityNum"
                  placeholder="请输入 容纳人数"
                  clearable
                  @blur="changeBlur($event,'capacityNum',999999,'integer')"
                  onkeyup="this.value=this.value.match(/^[1-9]\d*$/)">
          <template slot="append">人</template>
        </el-input>
      </template>
      <template slot="monthPrice">
        <el-input v-model="form.monthPrice"
                  placeholder="请输入 月租"
                  clearable
                  @blur="changeBlur($event,'monthPrice',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">元/月</template>
        </el-input>
      </template>
      <template slot="unitPrice">
        <el-input v-model="form.unitPrice"
                  placeholder="请输入 单价"
                  clearable
                  @blur="changeBlur($event,'unitPrice',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">元/m²/天</template>
        </el-input>
      </template>

      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
export default {
  name: "createForm",
  props: {
    createType: {
      type: String,
      default: 'create'
    },
    createIndex: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    },
    createInfo: {
      type: Object,
      default: () => {
        return {
          bedroomOfficeToilet: '',
          bedroom: '',
          office: '',
          toilet: '',
          image: '',
          floorage: '',
          sellPrice: '',
          capacityNum:'',
          monthPrice:'',
          unitPrice:'',
        }
      }
    }
  },
  data(){
    return{
      form: {
        capacityNum:'',
        monthPrice:'',
        unitPrice:'',
        bedroomOfficeToilet: '',
        bedroom: '',
        office: '',
        toilet: '',
        image: '',
        floorage: '',
        sellPrice: '',
      },
      loading: false,
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 200,
        column: [
          // {
          //   label: '户型',
          //   prop: 'bedroomOfficeToilet',
          //   span: 24,
          //   row: true,
          //   rules: [{
          //     required: true,
          //     message: "请选择户型",
          //     trigger: "blur"
          //   },{
          //     validator: (rule, value, callback) => {
          //       if(value === ''){
          //         callback(new Error('请选择户型'));
          //       }else{
          //         callback();
          //       }
          //     }
          //   }],
          // },
          {
            label: '户型图片',
            prop: 'image',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            tip: '仅1张,尺寸为184*184,最大20M',
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传户型图片",
              trigger: "blur"
            }]
          },
          {
            label: '建筑面积',
            prop: 'floorage',
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: '请输入建筑面积',
                trigger: 'blur'
              }
            ],
          },
          {
            label: '容纳人数',
            prop: 'capacityNum',
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: '请输入容纳人数',
                trigger: 'blur'
              }
            ],
          },
          {
            label: '月租',
            prop: 'monthPrice',
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: '请输入月租',
                trigger: 'blur'
              }
            ],
          },
          {
            label: '单价',
            prop: 'unitPrice',
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: '请输入单价',
                trigger: 'blur'
              }
            ],
          },
          // {
          //   label: '期望售价',
          //   prop: 'sellPrice',
          //   span: 24,
          //   row: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: '请输入期望售价',
          //       trigger: 'blur'
          //     }
          //   ],
          // }
        ]
      }
    }
  },
  mounted() {
    if(this.createType === 'edit') {
      this.form = {
        ...this.createInfo,
        bedroomOfficeToilet: this.createInfo.bedroom + '室' + this.createInfo.office + '厅' + this.createInfo.toilet + '卫',
      }
    }
  },
  methods: {
    // 选择户型---室
    changeBedroom(val){
      // console.log('选择户型---室',val)
      if(val && this.form.office !== '' && this.form.toilet !== ''){
        this.form.bedroomOfficeToilet = val + '室' + this.form.office + '厅' + this.form.toilet + '卫';
      }else{
        this.form.bedroomOfficeToilet = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 选择户型---厅
    changeOffice(val){
      // console.log('选择户型---厅',val)
      if(val !== '' && this.form.bedroom !== '' && this.form.toilet !== ''){
        this.form.bedroomOfficeToilet = this.form.bedroom + '室' + val + '厅' + this.form.toilet + '卫';
      }else{
        this.form.bedroomOfficeToilet = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 选择户型---卫
    changeToilet(val){
      // console.log('选择户型---卫',val)
      if(val !== '' && this.form.bedroom !== '' && this.form.office !== ''){
        this.form.bedroomOfficeToilet = this.form.bedroom + '室' + this.form.office + '厅' + val + '卫'
      }else{
        this.form.bedroomOfficeToilet = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    handleSubmit(form,done){
      // console.log('提交',form)
      done();
      this.$emit('createSubmit',{form: form,type: this.createType,index: this.createIndex})
    },
    handleClose(){
      this.$emit('close');
    },
    // 限制输入框
    changeBlur(e,text,max,type){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(value == 0) {    
            if (type === 'integer') {
              this.form[text] = Number(0)
            } else {
              this.form[text] = Number(0.10)
            }
            return
          }
          if(Number(value) > Number(max)){
            if (type === 'integer') {
              this.form[text] = Number(max)
            }else{
              this.form[text] = Number(max).toFixed(2)
            }
          }else{
            if (type === 'integer') {
              this.form[text] = Number(value)
            } else {
              this.form[text] = Number(value).toFixed(2)
            }
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },

    // 图片上传绑定事件

    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // // console.log(action)
          done();
        }
      })
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        column.propsHttp.name = res.path;
      }
    },
  }
}
</script>

<style scoped>

</style>